<template>
    <div class="carousel">
        <div class="carousel-images">
            <img
                v-for="(image, index) in images"
                :key="index"
                :src="image"
                :class="{'active': index === currentIndex}"
                alt="Carousel Image"
            />
        </div>
        <div class="carousel-indicators">
          <span
              v-for="(image, index) in images"
              :key="index"
              :class="{'active': index === currentIndex}"
              @click="goToSlide(index)"
          ></span>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        images: Array,
    },
    data() {
        return {
            currentIndex: 0,
        };
    },
    methods: {
        goToSlide(index) {
            this.currentIndex = index;
        },
    },
}
</script>

<style lang="scss" scoped>
.carousel {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.carousel-images {
    position: relative;
    overflow: hidden;
}

.carousel-images img {
    width: 100%;
    height: auto;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out; /* Transición suave */
}

.carousel-images img.active {
    opacity: 1;
    position: relative; /* La imagen activa será visible */
}

.carousel-indicators {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: relative;

    span {
        width: 16px !important;
        height: 16px !important;
        background-color: white !important;
        border: 1px solid #0090CB;

    }

    .active {
        background-color: #2E7326 !important;
        border: 1px solid #2E7326;
    }
}

.carousel-indicators span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #ddd;
    border-radius: 50%;
    cursor: pointer;
}

.carousel-indicators .active {
    background-color: #333;
}
</style>